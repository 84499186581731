import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZWJkODk4NjBhNjViN2ZhNWVjZDA3YzIzNGNjMTEwMTVkMWJlZGRmMmU5MmVmYTFjYWExNzk2ZGJkODg2ODMyYTU2M2VjYjk0ZGQ4YjRhNGYiLCJpYXQiOjE3MzA3MTExMDYuNTA2NTYzLCJuYmYiOjE3MzA3MTExMDYuNTA2NTY4LCJleHAiOjE3MzA3OTc1MDYuNTAyNDcsInN1YiI6IjY0Iiwic2NvcGVzIjpbXX0.CgFKwmCpOOuunHp6LEQ5ATdxtqjlfVsPSdLY16YxDFNcH9qb7smrEyRzWrPdiKbl4TyuhCJexNC_2r8KYf9jTQzdceVB8uX_Kw6dwAhH4PcI_8fWpTpOY1NV089JdUMdhs2B6U8DXTqkasuymQ9FWxNEWRGzfTXD0HHkCECND11hER2YYjNekbG85Y3K7J0Y7chLpfZirH3HDbqqWNjKM90xSfuM1uI9yeQ6Zb5o8q0VtFYQ4IdbcCqbBpxZTRWjGpGhH4Ep9Ez32JyW66ywNP2wnV4IkLnpcKKtGUm7_U_kGDzche3cBylQ6fJoWi-VkPinxhBnwi86_wHBMT0GXAmzPLi1h2Piwbp4FCpZSbJf7Mm7FT573LwlkRSLrfHiQ7_-4aNC-u0BTfeP7cL6173OxsObZJoa4AjlvCk0Ce9YOPoK40CmRYrEnBQB03coWwNOWbOmoYZCDGmnmy-6HCU6KxzVpiB5nIOcZK8rxGKrHIssJn2jsvMt9Pj_KQEcX7jhN8H2VqAt0CAN8NOAPAVpPec1cQCmyECMRmjSWi0P9p684_C3C-ARehq0baGqzEmauLTDDnstQvspyctwuRKM7GeSyRNSS18bO01l9TGSpzhNZnau0hv0UOmwMvVKtVjEoqqIwdKI1zP_A3ZwLw-oTeAuFNoK2mJNtsnQxA8',
    'Access-api-key': "eyJpdiI6IjBhNDZKaEhkejY2dkhOZ3J2a3FBdFE9PSIsInZhbHVlIjoiTjNHVE4yZmljQ2V1dlgxNWtTbkhrQT09IiwibWFjIjoiMjc0NDA5YWM2ZDcxOTQwZmM4YmIzNDg1NjQ0NTdhMzU5OTVlZWRmNWQwZGM0ZWM5MTJmZTg0NDllMDk2ODA4NiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6ImJZQzZvNWtLREwxckRubmtJdno0Mnc9PSIsInZhbHVlIjoiaTUxdmk0bkwvZlRmcW4rbm1Xb25UZz09IiwibWFjIjoiZTgxNWU4MTQ2NzMzZjhiMjAzMTdkMzdiNzI5N2Y3ZjJmZjVmODQ0MTYwZWY2ZDNmN2E1ZjIwN2IzOTYzYmU1ZCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}